import { gql } from "graphql-tag"

export const SET_NETWORKS_CONTAINER = gql`
          mutation {
            addNetworkContainer(id: "0", name: "Quantum") {
              _id
            }
          }
`

export const SET_NEW_NETWORK = gql`
          mutation addNetwork($name: String!, $id: String!) {
            addNetwork(networksID: "616575a9030d7c40047dac14", name: $name, id: $id) {
              _id
            }
          }
`

export const GET_NETWORKS = gql`
          query {
            getNetworks(id: "616575a9030d7c40047dac14") {
              _id
              networks_name {
                _id
                net_id
                name
                price
                payoff
                image
                description
                entry_number
                stripe_price_id
                plans {
                  plan_name
                  plan_description
                  plan_price
                }
              }
            }
          }
`

export const GET_USER = gql`
          query getUser($param: String!) {
            getUser(email: $param) {
              first_name
              last_name
              lists {
                network_id
              }
            }
          }
        `

export const GET_PLANS = gql`
          query {
            getPlans {
              plan_name
              plan_description
              plan_price
            }
          }
        `

export const GET_FEED = gql`
          query {
            getFeed {
              client_name
              email
              phone
              city
              image
              client_level
              network_id
              last_touch
              _id
            }
          }
        `


export const SUBSCRIBE_USER = gql`
          subscription($userID: ID) {
            getUserSub(userID: $userID) {
                  _id
                  email
                  first_name
                  last_name
                  password
                  profile_pic
                  join_datetime
                  stripe_id
                  require_new_bot
                  user_address {
                    _id
                    country
                    city
                    ZIP
                    street
                    housenumber            
                  }
                  card_details {
                    _id
                    name_on_card
                    card_number
                    expiry
                    ccv
                  }
                  is_company
                  tax_number
                  company_name
                  company_seat {
                    _id
                    country
                    city
                    ZIP
                    street
                    housenumber
                  }
                  demo_list {
                    _id
                    started_at
                    demo_start_date
                    order_quantity
                    network_id
                  }
                  lists {
                    _id
                    order_date
                    order_quantity
                    network_id
                    invoice_url
                    list_order
                    userID {
                      _id
                    }
                    name_list {
                      _id
                      client_name
                      client_pic
                      client_phone
                      client_city
                      client_email
                      status
                      recall_time
                      acquire_datetime
                      own_notes
                      feedback
                      is_panel_open
                    }       
                  }
            }
        }
`
export const SUBSCRIBE_LIVEFEED = gql`
            subscription {
              getLiveFeed {
                client_name
                email
                phone
                city
                image
                client_level
                network_id
                last_touch
                _id
              }
            }
          `;
          
export const GET_CURRENT_USER = gql`
          query {
            getCurrentUser {
                  _id
                  email
                  first_name
                  last_name
                  password
                  profile_pic
                  join_datetime
                  stripe_id
                  require_new_bot
                  user_address {
                    _id
                    country
                    city
                    ZIP
                    street
                    housenumber            
                  }
                  card_details {
                    _id
                    name_on_card
                    card_number
                    expiry
                    ccv
                  }
                  is_company
                  tax_number
                  company_name
                  company_seat {
                    _id
                    country
                    city
                    ZIP
                    street
                    housenumber
                  }
                  demo_list {
                    _id
                    started_at
                    demo_start_date
                    order_quantity
                    network_id
                  }
                  lists {
                    _id
                    order_date
                    order_quantity
                    network_id
                    invoice_url
                    list_order
                    userID {
                      _id
                    }
                    name_list {
                      _id
                      client_name
                      client_pic
                      client_phone
                      client_city
                      client_email
                      status
                      recall_time
                      acquire_datetime
                      own_notes
                      feedback
                      is_panel_open
                    }       
                  }
            }
          }
        `

export const SIGNIN_USER = gql`
mutation($email: String!, $password: String!) {
  signinUser(email: $email, password: $password) {
    token
  }
}
`
export const SET_ENTRY_STATUS = gql`
mutation($entryID: ID!, $newStatus: String!) {
  setEntryStatus(entryID: $entryID, newStatus: $newStatus) {
    _id
    status
  }
}
`

export const SIGNUP_USER = gql`
mutation($email: String!, $first_name: String!, $last_name: String!, $password: String!, $profile_pic: String!, $is_company: Boolean!, $requireNewBot: Boolean!) {
  signupUser(email: $email, first_name: $first_name, last_name: $last_name, password: $password, profile_pic: $profile_pic, is_company: $is_company, require_new_bot: $requireNewBot) {
		token
  }
}
`

export const ADD_NOTE = gql`
mutation($entryID: ID!, $note: String){
  addNote(entryID: $entryID, note: $note) {
    _id
  }
}
`

export const SET_PANEL_STATE = gql`
mutation($entryID: ID!){
  setPanelState(entryID: $entryID) {
    _id
  }
}
`

export const ADD_FEEDBACK = gql`
mutation($entryID: ID!, $feedback: String){
  addFeedback(entryID: $entryID, feedback: $feedback) {
    _id
  }
}
`

export const EXTEND_USER = gql`
mutation($userID: ID!, $is_company: Boolean!, $first_name: String!, $last_name: String!, $company_name: String, $tax_number: String, $user_country: String!, $user_city: String!, $user_street: String!, $user_housenumber: String!, $user_ZIP: String,
        $company_country: String, $company_city: String, $company_street: String, $company_housenumber: String, $company_ZIP: String, $stripe_id: String) {
    extendUser(userID: $userID, is_company: $is_company, first_name: $first_name, last_name: $last_name, company_name: $company_name, tax_number: $tax_number, user_country: $user_country, user_city: $user_city, user_street: $user_street, 
    user_housenumber: $user_housenumber, user_ZIP: $user_ZIP, company_country: $company_country, company_city: $company_city, company_street: $company_street, company_housenumber: $company_housenumber, company_ZIP: $company_ZIP, stripeID: $stripe_id) {
		  _id
    }
}
`