/* eslint handle-callback-err: "warn" */
import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'

import { client as apolloClient} from './main'
import { 
  GET_USER,
  SIGNIN_USER,
  SIGNUP_USER,
  EXTEND_USER,
  GET_CURRENT_USER,
  GET_NETWORKS,
  SET_ENTRY_STATUS,
  ADD_NOTE,
  ADD_FEEDBACK,
  GET_FEED,
  GET_PLANS,
  SET_PANEL_STATE
} from './queries'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userSubscriptions: null,
    user : null,
    feed : null,
    plans : null,
    networks : null,
    currentUser : null,
    backImages : null,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    networkColors: ['red', 'orange', 'purple', 'green']
  },
  mutations: {
    setSubscriptionButtonsState: (state, payload) => {
      state.subscriptionButtonsState = payload
    },
    setSubscriptions: (state, payload) => {
      state.userSubscriptions = payload
    },
    setImages: (state, payload) => {
      state.backImages = payload
    },
    setFeed: (state, payload) => {
      state.feed = payload
    },
    setCurrentUser: (state, payload) => {
      state.currentUser = payload
    },
    clearUser: state => (state.currentUser = null),
    setNetworks: (state, payload) => {
      state.networks = payload
    },
    setUser: (state, payload) => {
      state.user = payload
    },
    setPlans: (state, payload) => {
      state.plans = payload
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
  },
  actions: {
    setSubscriptionRenew: async ({ dispatch }, payload ) => {


      try {
        const response = await fetch("https://bboosterstripe.herokuapp.com/setsubscriptionrenew", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        });
        const res = await response.json()
        dispatch('getSubscriptions')
        return res
      } catch (error) {
        console.log('renew change error: ', error)
      }
    },

    getCurrentUser: ({ commit, dispatch }) => {
      apolloClient
      .query({
        query: GET_CURRENT_USER
      })
      .then(({ data }) => {
        console.log('GET CURRENT USER: ', data.getCurrentUser)
        commit('setCurrentUser', data.getCurrentUser)
        dispatch('getSubscriptions')
      })
      .catch(err => {
        console.log('CURRENT USER ERROR: ', err)
      })
    },

    getPlans: ({ commit }) => {
      apolloClient
      .query({
        query: GET_PLANS
      })
      .then(({ data }) => {
        commit('setPlans', data.getPlans)
      })
      .catch(err => {

      })
    },

    getFeed: ({ commit }) => {
      apolloClient
      .query({
        query: GET_FEED
      }).then(( {data} ) => {
        commit('setFeed', data.getFeed)
      }).catch(err => {

      })
    },

    getNetworks: ({ commit }) => {
      apolloClient
      .query({
        query: GET_NETWORKS
      })
      .then(({ data }) => {
        commit('setNetworks', data.getNetworks.networks_name)
      })
      .catch(err => {

      })
    },

    getUser: ({ commit }) => {
      apolloClient
      .query({
        query: GET_USER
      }).then(( {data} ) => {
        commit('setUser', data.getUser)
      }).catch(err => {

      })
    },

    getBackgroundImages: ({ commit }) => {
      fetch('https://api.pexels.com/v1/search?query=business&per_page=80', {
        headers: {
          Authorization: '563492ad6f917000010000012a8c496009db431185720bf8f8448d2b'
        }
      }).then(response => response.json())
        .then((response) => {
          commit('setImages', response)
        })
        .catch(err => {

        })
    },


    signinUser: async ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: SIGNIN_USER,
            variables: payload
          })
          .then(({ data }) => {
            localStorage.setItem('token', data.signinUser.token)
            resolve(data)
            router.go()
          })
          .catch(err => {
            reject(err)
          })
        });
    },

    setEntryStatus: async ({ commit }, payload) => {
      apolloClient
        .mutate({
          mutation: SET_ENTRY_STATUS,
          variables: payload
        })
        .then(({ data }) => {

        })
        .catch(err => {

        })
    },


    setPanelState: async ({ commit }, payload) => {
      apolloClient
        .mutate({
          mutation: SET_PANEL_STATE,
          variables: payload
        })
        .then(({ data }) => {

        })
        .catch(err => {

        })
    },

    signupUser: async ({ commit }, payload) => {
      apolloClient
        .mutate({
          mutation: SIGNUP_USER,
          variables: payload
        })
        .then(({ data }) => {
          router.push('login')
        })
        .catch(err => {

        })
    },

    addNote: async ({ commit }, payload) => {
      apolloClient
        .mutate({
          mutation: ADD_NOTE,
          variables: payload
        })
        .then(({ data }) => {
        })
        .catch(err => {
          console.log('error: ', err)
        })
    },

    addFeedback: async ({ commit }, payload) => {
      apolloClient
        .mutate({
          mutation: ADD_FEEDBACK,
          variables: payload
        })
        .then(({ data }) => {
        })
        .catch(err => {
          console.log('error: ', err)
        })
    },    

    signoutUser: async ({ commit }) => {
      commit('clearUser')
      localStorage.setItem('token', '')
      await apolloClient.resetStore()
      router.push('/')
    },

    extendUser: async ({ commit }, payload) => {
      apolloClient
        .mutate({
          mutation: EXTEND_USER,
          variables: payload
        })
        .then(({ data }) => {
          console.log('user updated: ', data);
        })
        .catch(err => {

        })
    },

    getSubscriptions: async ({ commit, state }, payload) => {
        if (state.currentUser) {
            if (state.currentUser.stripe_id) {
              console.log('stripe_id is present: ', state.currentUser.stripe_id)
              fetch("https://bboosterstripe.herokuapp.com/retrievecustomer?id=" + state.currentUser.stripe_id)
              .then((response) => response.json())
              .then((customer) => {
                console.log('subscription data: ', customer.subscriptions.data)
                commit('setSubscriptions', customer.subscriptions.data)
              })
              .catch((error) => {
                console.error('Error: ', error)
              })


            } else {
              console.log('stripe_id is not present, no operation');
            }

        } else {
          console.log('no user defined, no operation');
        }
    },

  },

  getters : {
    user : state => state.user,
    currentUser : state => state.currentUser,
    getFeed : state => state.feed,
    getPlans : state => state.plans,
    networks : state => state.networks,
    getBackImages : state => state.backImages,
    networkColors : state => state.networkColors,
    getSubscriptions : state => state.userSubscriptions,
  },
})
